import {
  DELIVERY_STATUS_OUT_FOR_DELIVERY,
  DELIVERY_STATUS_COMPLETED,
  DELIVERY_STATUS_FAILED,
  DELIVERY_STATUS_IN_WAREHOUSE,
  DELIVERY_STATUS_MIXED,
  DELIVERY_STATUS_ASSIGNED,
  DELIVERY_STATUS_OPEN,
  DELIVERY_STATUS_TRANSPORT_REQUEST,
  DELIVERY_STATUS_SELF_COLLECT,
  DELIVERY_STATUS_NEW,
} from "../common/constants/deliveryStatuses"

export const supportedShipmentListHeaders = [
  "receiverName",
  "dropoffAddress",
  "dropoffScheduledDate",
  "dropoffScheduledTime",
  "pickupAddress",
  "pickupLocationName",
  "pickupScheduledDate",
  "pickupScheduledTime",
  "shipmentId",
  "deliveryStatus",
  "receiverStatus",
  "paymentStatus",
  "paymentAmount",
  "vehicleRegistration",
  "updatedDate",
  "containerNumber",
  "warehouseLocation",
  "unitCount",
  "temperature",
  "referenceNumber",
  "weight",
  "driverNote",
  "senderName",
  "carrier",
  "shipmentOwner",
  "description",
  "vehicleName",
]

export const deliveryStatusHeaders = [
  DELIVERY_STATUS_OUT_FOR_DELIVERY,
  DELIVERY_STATUS_COMPLETED,
  DELIVERY_STATUS_FAILED,
  DELIVERY_STATUS_IN_WAREHOUSE,
  DELIVERY_STATUS_MIXED,
  DELIVERY_STATUS_ASSIGNED,
  DELIVERY_STATUS_OPEN,
  DELIVERY_STATUS_TRANSPORT_REQUEST,
  DELIVERY_STATUS_SELF_COLLECT,
  DELIVERY_STATUS_NEW,
]
